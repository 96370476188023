import { Globals, defaultWhite, Segment, Mode } from './globals'
import { seqFunc } from './sequencer'
import { setupMainRecord, setupRecorder } from './recorder'
import { setupWs } from './websocket'
import {
  getMagnitudesDome,
  getMagnitudesFlat,
  drawWaveformDome,
  drawWaveformFlat,
  drawGradientCircle
} from './waveform'

import {
  toggleRecording,
  drawLineDome,
  drawLineFlat
} from './ui'

function init(mode: Mode) {
  const APPID_MCORP = '2586833180177395011'
  const app = MCorp.app(APPID_MCORP, { anon: true }) // eslint-ignore-line
  app.ready
    .then(() => {
      const loopLengthSec = 20
      const loopLengthMs = 20 * 1000
      const timingObject = new TIMINGSRC.TimingObject()
      const loopTime = new TIMINGSRC.LoopConverter(timingObject, [0, loopLengthSec])
      const dataSet = new TIMINGSRC.Dataset()
      const sequencer = new TIMINGSRC.Sequencer(dataSet, loopTime)

      const recButton = (document.getElementById('recButton') || document.createElement('button')) as HTMLButtonElement
      const canvas = (document.getElementById('canvas') || document.createElement('canvas')) as HTMLCanvasElement
      const canvasCtx = canvas.getContext('2d') || new CanvasRenderingContext2D()

      const magFunc = mode === 'flat' ? getMagnitudesFlat : getMagnitudesDome
      const drawFunc = mode === 'flat' ? drawWaveformFlat : drawWaveformDome
      const drawLineFunc = mode === 'flat' ? drawLineFlat : drawLineDome
      const audioContext = new AudioContext()

      const g: Globals = {
        mode,
        live: false,
        participants: 0,

        audioContext,
        mainVol: audioContext.createGain(),
        limiterNode: audioContext.createDynamicsCompressor(),

        bidirectional: false,
        color: defaultWhite,
        loopLengthSec,
        loopLengthMs,
        frippLengthSec: loopLengthSec * 5,  // five times 
        frippLengthMs: loopLengthMs * 5,  // five times 

        pixelsPerSec: 1,
        boxWidth: 10,

        segments: new Map(),
        timingObject,
        loopTime,
        dataSet,
        sequencer,

        recButton,
        canvas,
        canvasCtx
      }
      g.timingObject.timingsrc = app.motions.shared
      g.mainVol.connect(g.audioContext.destination)

      // Creating a compressor but setting a high threshold and
      // high ratio so it acts as a limiter. More explanation at
      // https://developer.mozilla.org/en-US/docs/Web/API/DynamicsCompressorNode
      g.limiterNode.threshold.setValueAtTime(-5.0, audioContext.currentTime) // In Decibels
      g.limiterNode.knee.setValueAtTime(0, audioContext.currentTime) // In Decibels
      g.limiterNode.ratio.setValueAtTime(40.0, audioContext.currentTime) // In Decibels
      g.limiterNode.attack.setValueAtTime(0.001, audioContext.currentTime) // Time is seconds
      g.limiterNode.release.setValueAtTime(0.1, audioContext.currentTime) // Time is seconds

      g.limiterNode
        .connect(g.mainVol)
        .connect(audioContext.destination)


      g.mainVol.gain.linearRampToValueAtTime(1, g.audioContext.currentTime + 0.5)

      const seqOff = sequencer.on('change', seqFunc(g))
      console.log(seqOff)
      const onResize = () => {
        const h = window.innerHeight
        canvas.width = mode === 'flat' ? window.innerWidth : h
        canvas.height = h
        if (mode === 'flat') {
          recButton.style.width = window.innerHeight * 0.4 + 'px'
          recButton.style.height = window.innerHeight * 0.4 + 'px'
          recButton.style.fontSize = window.innerHeight * 0.1 + 'px'
        }
        g.pixelsPerSec = canvas.width / loopLengthSec
        g.boxWidth = canvas.width / 200
        g.segments.forEach((s, key, map) => map.set(
          key,
          Object.assign(s, { magnitudes: s.buffer ? magFunc(g)(s.buffer) : [] })
        ))
      }
      window.addEventListener('resize', onResize)

      onResize()

      setupRecorder(g, magFunc)

      setupWs(g, magFunc)

      if (mode === 'dome') {
        setupMainRecord(g)
      }

      recButton.addEventListener('click', toggleRecording(g))

      let lastCalledTime = performance.now()
      let delta = 0
      let fps = 0
      const done = false
      const inBuffer = (s: Segment, loopInSec: number) => {
        if (!s.length) return false
        if (s.loopStartSec + s.length > loopLengthSec) {
          const rem = (s.loopStartSec + s.length) % loopLengthSec
          return (loopInSec >= 0 && loopInSec <= rem) || (loopInSec > s.loopStartSec && loopInSec <= loopLengthSec)
        }
        return loopInSec > s.loopStartSec && loopInSec < (s.loopStartSec + s.length)
      }
      const animateLine = () => {

        const nowInSec = timingObject.query().position
        const loopInSec = loopTime.query().position
        canvasCtx.reset()
        canvasCtx.lineWidth = 5

        // Background
        canvasCtx.fillStyle = 'black'
        canvasCtx.fillRect(0, 0, canvas.width, canvas.height)

        if (mode === 'dome') {
          drawGradientCircle(g)
        }

        g.segments.forEach((s, key) => {
          const alpha = inBuffer(s, loopInSec)
            ? 1
            : 0.5
          canvasCtx.fillStyle = 'hsl(' + s.color + ' / ' + alpha + ')'
          drawFunc(g)(nowInSec, key, s)
        })

        canvasCtx.resetTransform()
        if (mode === 'dome') {
          // FPS
          delta = (performance.now() - lastCalledTime) / 1000
          lastCalledTime = performance.now()
          fps = 1 / delta

          canvasCtx.moveTo(0, 0)
          canvasCtx.fillStyle = 'white'
          canvasCtx.font = 'normal 16pt Arial'
          canvasCtx.fillText(g.participants + ':' + Math.floor(fps), 10, 26)
        }

        drawLineFunc(g)(nowInSec)

        if (!done) window.requestAnimationFrame(animateLine)
      }
      window.requestAnimationFrame(animateLine)
      window.onbeforeunload = function() {
        if (g.ws) g.ws.close()
      }

    })
}

if (window.location.pathname.match('dome')) {
  init('dome')
} else {
  init('flat')
}
