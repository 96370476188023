import type { Globals, Segment } from './globals'
import type { MagnitudeFunc } from './waveform'
import { encode } from '@msgpack/msgpack'
import { requestFullScreen } from './fullscreen'

// peer id regex (!/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(props[propName])) {
const padit = (n: number) => String(n).padStart(2, '0')
const floorPad = (n: number) => padit(Math.floor(n))

const formatTime = (pos?: number, subsec = false): string => pos === null || pos === undefined
  ? ''
  : isFinite(pos)

    ? pos > 60 * 60
      ? Math.floor(pos / (60 * 60)) + ':' + floorPad((pos / 60) % 60) + ':' + floorPad(pos % 60)
      : pos > 1
        ? Math.floor((pos / 60) % 60) + ':' + floorPad(pos % 60)
        : subsec ? pos.toPrecision(2) + ' sec' : '0:00'
    : 'live'


const getEndingFromMime = (mimeType: string) => mimeType && mimeType.indexOf('webm') !== -1
  ? 'webm'
  : mimeType && mimeType.indexOf('mp4') !== -1
    ? 'mp4'
    : mimeType && mimeType.indexOf('m4a') !== -1
      ? 'm4a'
      : null


export const getName = (ending: string | null) => Date.now() + '_' + Math.random().toString(36).slice(2) + (ending ? '.' + ending : '')

export const getUpName = (mimeType: string) => {
  const ending = getEndingFromMime(mimeType)
  return getName(ending)
}

export const blobToArrayBuffer = (blob: Blob) => blob.arrayBuffer()
  .then(arraybuffer => ({
    arraybuffer: new Uint8Array(arraybuffer), // encode the uint8 "view"
    mime: blob.type
  }))

let intvl = 0, count = 0

export const setRecButton = (g: Globals, mode: 'rec' | 'wait' | 'stop') => {
  if (intvl) clearInterval(intvl)
  if (g.mode === 'dome') {
    if (mode === 'stop') {
      g.recButton.innerHTML = 'stop'
    } else {
      g.recButton.innerHTML = 'rec'
    }
    return
  }
  if (mode === 'rec') {
    g.recButton.disabled = false
    g.recButton.style.background = 'rgba(255,0,0, 1)'
    g.recButton.style.borderWidth = '0px'
    g.recButton.style.color = 'rgba(0,0,0, 1)'
    g.recButton.innerHTML = 'record'
  } else if (mode === 'wait') {
    g.recButton.style.borderRadius = '50%'
    g.recButton.disabled = true
    g.recButton.style.background = 'rgba(255,0,0, 0.1)'
    g.recButton.style.color = 'rgba(255,0,0, 0.6)'
    g.recButton.style.borderWidth = '1em'
    count = Math.floor(3 + (Math.random() * 20))
    g.recButton.innerHTML = 'wait ' + count--
    intvl = setInterval(() => {
      if (count < 1) {
        return setRecButton(g, 'rec')
      }
      g.recButton.innerHTML = 'wait ' + count--
    }, 1000)

  } else if (mode === 'stop') {
    g.recButton.style.borderRadius = '5%'
    g.recButton.style.background = 'rgba(127,127,127, 0.7)'
    g.recButton.style.color = 'rgb(0,0,0)'
    g.recButton.style.borderWidth = '0px'
    count = 10
    g.recButton.innerHTML = 'stop ' + count--
    intvl = setInterval(() => {
      if (count < 1) {
        g.mediaRecorder?.stop()
        return setRecButton(g, 'wait')
      }
      g.recButton.innerHTML = 'stop ' + count--
    }, 1000)
  }
}

export const setupRecorder = (g: Globals, magFunc: MagnitudeFunc) => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: false,
        noiseSuppression: false,
        autoGainControl: true
      }
    })
      .then(function(stream) {
        g.audioStream = stream
        g.mediaRecorder = new MediaRecorder(stream)

        let name = ''
        let seg: Segment = { blobs: [], magnitudes: [], length: 0, timeStartSec: 0, loopStartSec: 0, color: g.color }

        g.mediaRecorder.onstart = function() {
          name = getUpName(g.mediaRecorder?.mimeType || 'audio/m4a')
          setRecButton(g, 'stop')
          g.mainVol.gain.setValueAtTime(0, g.audioContext.currentTime + 0.1)
          seg = {
            color: g.color,
            timeStartSec: g.timingObject.query().position,
            loopStartSec: g.loopTime.query().position,
            length: 0,
            blobs: [],
            magnitudes: []
          }
          g.segments.set(name, seg)
          g.dataSet.update({
            key: name,
            interval: new TIMINGSRC.Interval(seg.loopStartSec),
            data: {}
          })
        }

        g.mediaRecorder.ondataavailable = function(e) {
          seg.blobs.push(e.data)
          const audioBlob = new Blob(seg.blobs, { type: e.data.type })


          audioBlob.arrayBuffer()
            .then(ab => g.audioContext
              ? g.audioContext.decodeAudioData(ab)
              : Promise.reject(new Error('audio context NOT initialized'))
            )
            .then(buffer => {
              const magnitudes = magFunc(g)(buffer)
              seg.buffer = buffer
              seg.magnitudes = magnitudes
              seg.length = buffer.duration
              if (g.ws) {
                blobToArrayBuffer(e.data) // only send this one packet of 
                  .then(({ arraybuffer, mime }) => {
                    g.ws?.send(
                      encode({
                        type: 'WS_SEND_BLOB',
                        arraybuffer,
                        length: seg.length,
                        mime,
                        name,
                        loopStartSec: seg.loopStartSec,
                        timeStartSec: seg.timeStartSec,
                        color: g.color
                      }))
                  })
                  .catch(console.log)
              }
            })
            .catch(console.log)
        }

        g.mediaRecorder.onstop = function() {
          setRecButton(g, 'wait')
        }
      })
      .catch(function(err) {
        console.error('Error accessing microphone:', err)
      })
  } else {
    console.error('getUserMedia not supported on your browser!')
  }
}

export const toggleMainRecord = (g: Globals) => () => {
  if (g.audioContext.state === 'suspended') {
    g.audioContext.resume().catch(console.warn)
  }
}

const recordMainOutput = async (g: Globals) => {
  if (!g.audioContext || !('showSaveFilePicker' in window)) {
    return
  }

  const osc = g.audioContext.createOscillator()
  const gain = g.audioContext.createGain()
  osc.frequency.setValueAtTime(440, g.audioContext.currentTime)
  osc.start()
  gain.gain.setValueAtTime(0, g.audioContext.currentTime)

  const mediastreamdest = g.audioContext.createMediaStreamDestination()
  const mediaRecorder = new MediaRecorder(mediastreamdest.stream, { audioBitsPerSecond: 256000, mimeType: "audio/webm;codecs=opus" })
  console.log('mime', mediaRecorder.mimeType)

  const handle = await window.showSaveFilePicker({ suggestedName: 'dome-' + (new Date()).toISOString() + '.webm' })
  const writable = await handle.createWritable()

  g.mainVol.connect(mediastreamdest)
  // we connect a no-volume oscillator to keep the recorder fed
  // if we don't, the mediastreamdest only gets the unmute event when audio comes in
  // this is so dumb
  osc.connect(gain).connect(mediastreamdest)

  console.log('start recording')
  mediaRecorder.addEventListener('stop', onStop)
  mediaRecorder.addEventListener('dataavailable', onData)
  mediaRecorder.start(1000)

  async function onData(e: BlobEvent) {
    if (e.data) {
      await writable.write(e.data)
    }
    if (mediaRecorder.state === "inactive") {
      await writable.close()
      osc.stop()
      osc.disconnect()
      gain.disconnect()
      g.mainVol.disconnect()
      g.mainVol.connect(g.audioContext.destination)
      mediaRecorder.removeEventListener('dataavailable', onData)
    }
  }
  function onStop(e: Event) {
    console.log('stop recording main', e)
    mediaRecorder.removeEventListener('stop', onStop)
  }
  return mediaRecorder
}

export const setupMainRecord = (g: Globals) => {
  const fsbut = document.getElementById('fsButton')
  if (fsbut) {
    const reqFs = () => requestFullScreen(document.documentElement)
    fsbut.addEventListener('click', reqFs)
  }
  const mainRecbut = document.getElementById('mainRecButton')

  if (mainRecbut) {

    if (!('showSaveFilePicker' in window)) {
      mainRecbut.style.display = 'none'
      mainRecbut.style.visibility = 'hidden'
      return
    }
    mainRecbut.style.display = 'inline-block'
    mainRecbut.style.visibility = 'visible'

    if (!g.audioContext) return

    let mainRecorder: MediaRecorder | undefined
    let interval: number | undefined
    let time = 0
    const checkBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      return true
    }

    const toggleMainRecorder = () => {
      if (mainRecorder) {
        if (interval) {
          clearInterval(interval)
        }
        mainRecorder.stop()
        mainRecbut.innerHTML = 'record main out'
        mainRecbut.style.color = 'black'
        mainRecbut.style.background = '#cccccc'
        mainRecorder = undefined
        window.removeEventListener('beforeunload', checkBeforeUnload)
      } else {
        window.addEventListener('beforeunload', checkBeforeUnload)
        recordMainOutput(g)
          .then(mr => {
            time = 0
            mainRecbut.innerHTML = 'stop recording'
            mainRecbut.style.background = 'black'
            mainRecbut.style.color = 'white'
            mainRecorder = mr
            // set time
            interval = setInterval(() => {
              time++
              mainRecbut.innerHTML = 'stop ' + formatTime(time)
            }, 1000)
          })
          .catch(console.warn)
      }

    }
    mainRecbut.addEventListener('click', toggleMainRecorder)
  }
}
