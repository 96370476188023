import { defaultWhite, Globals } from './globals'
import type { MagnitudeFunc } from './waveform'
import { decode } from '@msgpack/msgpack'

type WsCount = {
  type: 'WS_COUNT'
  count: number
}

type WsSendNoBlob = { type: 'WS_SEND_NOBLOB', name: string, length: number, timeStartSec: number, loopStartSec: number, color: string }
type WsSendBlobAsBuffer = {
  type: 'WS_SEND_BLOB',
  arraybuffer: ArrayBuffer,
  mime: string,
  name: string,
  length: number,
  timeStartSec: number,
  loopStartSec: number,
  color: string
}
type WsSend = { type: 'WS_SEND', data: Record<string, unknown> }
type SetState = { type: 'SET_STATE', live: boolean, bidirectional: boolean }
type MrColor = { type: 'MR_COLOR', color: string }

export const setupWs = (g: Globals, magFunc: MagnitudeFunc) => {
  const prot = window.location.protocol.toLowerCase() === 'https:' ? 'wss://' : 'ws://'
  const wsurl = prot + window.location.host + '/websocket/' + (g.mode === 'dome' ? 'dome' : 'main')
  console.log('opening wsurl', wsurl)
  // g.ws = new ReconnectingWebSocket(wsurl)
  g.ws = new WebSocket(wsurl)
  g.ws.binaryType = 'arraybuffer'


  const onError = (e: Event) => console.log('error ws:', e)
  const onOpen = () => console.log('open ws')
  const onClose = () => {
    console.log('close ws')
    setTimeout(() => {
      setupWs(g, magFunc)
    }, 2000)
  }

  function onMessage(event: MessageEvent) {
    const msg = decode(event.data as ArrayBuffer) as MrColor | SetState | WsSend | WsSendBlobAsBuffer | WsCount | WsSendNoBlob
    if (msg.type === 'MR_COLOR' && msg.color) {
      g.color = msg.color
      g.segments.forEach(s => {
        if (s.color === defaultWhite) {
          s.color = msg.color
        }
      })
    }
    else if (msg.type === 'WS_COUNT' && 'count' in msg) {
      console.log('got count', msg.count)
      g.participants = msg.count
    }
    else if (msg.type === 'SET_STATE') {
      g.live = msg.live
      g.bidirectional = msg.bidirectional
      if (g.mode === 'flat') {
        g.recButton.style.display = g.live ? 'flex' : 'none'
      }
    }
    else if (msg.type === 'WS_SEND_NOBLOB') {
      const seg = g.segments.get(msg.name) || { color: '', length: 0, blobs: [], magnitudes: [], loopStartSec: 0, timeStartSec: 0 }
      seg.color = msg.color
      seg.length = msg.length || 0
      seg.magnitudes = []
      seg.blobs = []
      seg.loopStartSec = msg.loopStartSec
      seg.timeStartSec = msg.timeStartSec
      g.segments.set(msg.name, seg)
    }
    else if (msg.type === 'WS_SEND_BLOB') {
      const blob = new Blob([msg.arraybuffer], { type: msg.mime })
      const seg = g.segments.get(msg.name) || {
        color: msg.color,
        length: msg.length || 0,
        magnitudes: [],
        blobs: [],
        loopStartSec: msg.loopStartSec,
        timeStartSec: msg.timeStartSec
      }
      seg.blobs = seg.blobs.concat(blob)
      // set to global segments before we decode (in case something happens, we have the blobs on record)
      g.segments.set(msg.name, seg)
      const b = new Blob(seg.blobs, { type: msg.mime })
      b.arrayBuffer()
        .then(ab => {
          return g.audioContext
            ? g.audioContext.decodeAudioData(ab)
            : Promise.reject(new Error('audio context not initialized'))
        })
        .then(buffer => {
          const magnitudes = magFunc(g)(buffer)
          seg.magnitudes = magnitudes
          seg.buffer = buffer
          seg.length = msg.length
          g.dataSet.update({
            key: msg.name,
            interval: new TIMINGSRC.Interval(seg?.loopStartSec || 0),
            data: {}
          })

        })
        .catch(console.log)
    }
  }
  g.ws.addEventListener('error', onError)
  g.ws.addEventListener('open', onOpen)
  g.ws.addEventListener('close', onClose)
  g.ws.addEventListener('message', onMessage)
}
