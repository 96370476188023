import type { Globals, Segment } from './globals'

export type MagnitudeFunc = (g: Globals) => (ab: AudioBuffer) => Array<number>
export type WaveformFunc = (g: Globals) => (nowInSec: number, key: number | string, seg: Segment) => void

const degToRad = (degrees: number) => {
  const pi = Math.PI
  return degrees * (pi / 180)
}

const polToCart = (r: number, thetaDeg: number) => {
  const x = r * Math.cos(degToRad(thetaDeg))
  const y = r * Math.sin(degToRad(thetaDeg))
  return { x, y }
}

export const getMagnitudesDome: MagnitudeFunc = g => (audioBuffer) => {
  const samplesPerDegree = audioBuffer.sampleRate * g.loopLengthSec / 360
  const boxSkip = Math.floor(samplesPerDegree * 1.5)

  const data = audioBuffer.getChannelData(0)
  const magnitudes = []
  for (let i = 0; i < audioBuffer.length; i += boxSkip) {
    let mag = 0
    for (let j = 0; j < boxSkip; j++) {
      mag += Math.abs(data[i])
    }
    magnitudes.push(mag / boxSkip)
  }
  return magnitudes
}


export const drawWaveformDome: WaveformFunc = g => (nowInSec, key, seg) => {
  const thetaStart = seg.loopStartSec / g.loopLengthSec * 360
  const timeDiffSec = nowInSec - seg.timeStartSec
  if (timeDiffSec > g.frippLengthSec) {
    g.segments.delete(key)
    g.dataSet.removeCue(key)
    return
  }
  const ratio = 1 - (timeDiffSec / g.frippLengthSec)

  const y = (ratio * (g.canvas.height / 2.2)) + 10   // radius

  const base = g.canvas.height / 3

  g.canvasCtx.resetTransform()
  g.canvasCtx.translate(g.canvas.width / 2, g.canvas.height / 2)
  g.canvasCtx.rotate((thetaStart + 90) * Math.PI / 180)


  for (let i = 0; i < seg.magnitudes.length; i++) {

    const degrees = i * 1.5
    const h = 2 + seg.magnitudes[i] * base * ratio
    // canvasCtx.translate(0, y)

    const p1 = polToCart(y + h / 2, degrees)
    const p2 = polToCart(y + h / 2, degrees + 1)
    const p3 = polToCart(y - h / 2, degrees + 1)
    const p4 = polToCart(y - h / 2, degrees)

    g.canvasCtx.beginPath()
    g.canvasCtx.moveTo(p1.x, p1.y)
    g.canvasCtx.lineTo(p2.x, p2.y)
    g.canvasCtx.lineTo(p3.x, p3.y)
    g.canvasCtx.lineTo(p4.x, p4.y)
    g.canvasCtx.fill()

  }
}


export const getMagnitudesFlat: MagnitudeFunc = g => audioBuffer => {
  const data = audioBuffer.getChannelData(0)
  const samplesPerPixel = audioBuffer.sampleRate / g.pixelsPerSec
  const boxSkip = Math.floor(g.boxWidth * 1.5 * samplesPerPixel)
  const magnitudes = []
  for (let i = 0; i < audioBuffer.length; i += boxSkip) {
    let mag = 0
    for (let j = 0; j < boxSkip; j++) {
      mag += Math.abs(data[i])
    }
    magnitudes.push(mag / boxSkip)
  }
  return magnitudes
}

export const drawWaveformFlat: WaveformFunc = g => (nowInSec, key, seg) => {
  const timeDiff = nowInSec - seg.timeStartSec
  if (timeDiff > g.frippLengthSec) {
    g.segments.delete(key)
    g.dataSet.removeCue(key)
    return
  }
  const waveH = g.canvas.height / 5
  const base = (seg.loopStartSec / g.loopLengthSec) * g.canvas.width
  const y = g.canvas.height - (timeDiff * (g.canvas.height / g.frippLengthSec))
  const yHalf = y - waveH / 2
  if (seg.magnitudes.length === 0) {
    g.canvasCtx.resetTransform()
    if (seg.length + seg.loopStartSec > g.loopLengthSec) {
      g.canvasCtx.fillRect(base, yHalf, g.pixelsPerSec * (g.loopLengthSec - seg.loopStartSec), waveH)
      g.canvasCtx.fillRect(0, yHalf, g.pixelsPerSec * (seg.loopStartSec + seg.length - g.loopLengthSec), waveH)
    } else {
      g.canvasCtx.translate(base, y - waveH / 2)
      g.canvasCtx.fillRect(0, 0, g.pixelsPerSec * seg.length, waveH)
    }
    return
  }
  for (let i = 0; i < seg.magnitudes.length; i++) {
    const h = seg.magnitudes[i] * waveH
    const x = i * g.boxWidth * 1.5
    g.canvasCtx.resetTransform()
    g.canvasCtx.translate((base + x) % g.canvas.width, y - 1 - h / 2)
    g.canvasCtx.fillRect(0, 0, g.boxWidth, 2 + h)
  }
}

export const drawGradientCircle = (g: Globals) => {
  const radius = g.canvas.height / 2
  const x = g.canvas.width / 2,
    y = radius,
    // Radii of the white glow.
    innerRadius = 5, // canvas.height / 2,
    outerRadius = radius - 10

  const gradient = g.canvasCtx.createRadialGradient(x, y, innerRadius, x, y, outerRadius);
  gradient.addColorStop(0, 'black')
  gradient.addColorStop(1, 'rgb(0,0,64)')

  g.canvasCtx.arc(x, y, radius, 0, 2 * Math.PI)

  g.canvasCtx.fillStyle = gradient
  g.canvasCtx.fill()
}
