import type { Globals } from './globals'
import { requestFullScreen, isFullScreen, requestWakeLock } from './fullscreen'

export type DrawLineFunc = (g: Globals) => (now: number) => void

export const toggleRecording = (g: Globals) => () => {
      if (g.mediaRecorder && g.mediaRecorder.state === 'recording') {
            g.mediaRecorder.stop()
      } else if (g.mediaRecorder) {
            if (!isFullScreen()) {
                  requestFullScreen(document.documentElement)
                  requestWakeLock()
            }
            g.audioContext?.resume()
            g.mediaRecorder.start(500)
      }
}


export const drawLineFlat: DrawLineFunc = (g: Globals) => (nowInSec: number) => {
      // line from left to right
      const x = ((nowInSec % g.loopLengthSec) / g.loopLengthSec) * g.canvas.width
      g.canvasCtx.lineWidth = 3
      g.canvasCtx.strokeStyle = "pink"
      g.canvasCtx.beginPath()
      g.canvasCtx.moveTo(x, 0)
      g.canvasCtx.lineTo(x, g.canvas.height)
      g.canvasCtx.stroke()
}

export const drawLineDome: DrawLineFunc = (g: Globals) => (nowInSec: number) => {
      // line going around in circle 
      const timeInLoopSec = nowInSec % g.loopLengthSec
      const theta = timeInLoopSec / g.loopLengthSec * 360
      // const thetaStart = seg.loopStart / loopLengthMs * 360
      g.canvasCtx.lineWidth = 3
      g.canvasCtx.strokeStyle = "pink"

      g.canvasCtx.resetTransform()

      g.canvasCtx.translate(g.canvas.width / 2, g.canvas.height / 2)
      g.canvasCtx.rotate(theta * Math.PI / 180)
      g.canvasCtx.beginPath()
      g.canvasCtx.moveTo(0, 0)
      g.canvasCtx.lineTo(0, g.canvas.height / 2)
      g.canvasCtx.stroke()
}
