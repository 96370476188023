import type { Globals } from './globals'

export const seqFunc = (g: Globals) => (e: { key: string }) => {
  if (g.bidirectional === false && g.mode === 'flat') return
  const nowInSec = g.timingObject.query().position
  const seg = g.segments.get(e.key)
  if (g.audioContext && seg && seg.buffer && seg.buffer.duration) {
    const timeDiffSec = nowInSec - seg.timeStartSec
    // don't play if we are outside of frippLengthSec
    if (timeDiffSec > g.frippLengthSec) return
    const v = 1.1 - (timeDiffSec / g.frippLengthSec)
    const vol = v * v * v
    // console.log('trigger, vol:', vol, 'seg:', seg)

    if (seg.buffer) {
      const bufferNode = g.audioContext.createBufferSource()
      const gain = g.audioContext.createGain()
      bufferNode
        .connect(gain)
        .connect(g.limiterNode)
      bufferNode.buffer = seg.buffer
      const len = seg.buffer.duration
      const fadeinEnd = len * 0.001
      const fadeoutStart = len - (len * 0.001)
      // console.log('len', len, fadeinEnd, fadeoutStart)
      gain.gain.setValueAtTime(0, g.audioContext.currentTime)
      gain.gain.linearRampToValueAtTime(vol, g.audioContext.currentTime + fadeinEnd)
      gain.gain.setValueAtTime(vol, g.audioContext.currentTime + fadeoutStart)
      gain.gain.exponentialRampToValueAtTime(0.00000001, g.audioContext.currentTime + len)
      bufferNode.onended = () => {
        bufferNode.disconnect()
        gain.disconnect()
      }
      bufferNode.start()
    }
  }
}
