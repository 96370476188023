export type Segment = {
  color: string,
  buffer?: AudioBuffer
  blobs: Array<Blob>
  length: number
  magnitudes: Array<number>
  timeStartSec: number
  loopStartSec: number
}

export type Mode = 'flat' | 'dome'
export const defaultWhite = '1 0% 100%'

export type Globals = {
  live: boolean
  bidirectional: boolean
  mode: Mode
  ws?: WebSocket
  color: string
  participants: number

  audioContext: AudioContext
  mainVol: GainNode
  limiterNode: DynamicsCompressorNode
  audioStream?: MediaStream
  mediaRecorder?: MediaRecorder

  pixelsPerSec: number
  boxWidth: number

  loopLengthSec: number
  loopLengthMs: number
  frippLengthSec: number
  frippLengthMs: number

  timingObject: TIMINGSRC.TimingObject
  loopTime: TIMINGSRC.LoopConverter
  dataSet: TIMINGSRC.Dataset
  sequencer: TIMINGSRC.Sequencer

  segments: Map<string | number, Segment>

  recButton: HTMLButtonElement
  canvas: HTMLCanvasElement
  canvasCtx: CanvasRenderingContext2D

}
